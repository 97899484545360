<template>
    <div class="form">
        <div class="background background_blue background_lawyer background_lawyer_q">
            <div class="background__image background__image_lawyer"></div>
        </div>

        <div class="wrapper">
            <div class="content">
                <div class="content__container lawyerQuestionnaire">
                    <p class="lawyerQuestionnaire__title">{{ "l_anket" | localize }}</p>

                    <Questionnaire service="lawyers" prefix="l" nextlink="/lawyers-benefits" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Questionnaire from '@/components/common/Questionnaire.vue'

export default {
  name: 'Lawyers_Questionnaire',
  components: { Questionnaire }
}
</script>